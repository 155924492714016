import {
    GetUsersRoles,
} from "../api";
import { GetDepartmentsAll } from "@/views/departments/api";
import { ShowApiError } from "@/request/error";
import { GetUsersAll } from "@/views/users/api";
export default {
    data() {
        return {
            rolesOptions: [],
            departmentOptions: [],
            userOptions: []
        }
    },
    methods: {
        initFormOptions() {
            GetUsersAll().then((res) => {
                this.userOptions = res.data;
            })
            GetDepartmentsAll()
            .then((res) => {
                this.departmentOptions = res.data;
            })
            .catch((err) => {
                ShowApiError("获取部门选单失败", err);
            });
            GetUsersRoles()
                    .then((res) => {
                        this.rolesOptions = res.data;
                    })
                    .catch((err) => {
                        ShowApiError("获取用户角色选单失败", err);
                    });
        },
    }
}