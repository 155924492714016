<template>
  <el-form
    :model="form"
    ref="form"
    label-width="100px"
    class="company-ruleForm"
    label-position="top"
  >
    <el-alert
      :closable="false"
      title="Tips：输入完成后敲击回车可直接新增一行"
      type="info"
    >
    </el-alert>
    <el-row :gutter="10" v-for="(item, index) in form.items" :key="item.key">
      <el-col :span="8">
        <el-form-item
          label="用户"
          :prop="'items.' + index + '.userName'"
          :rules="[{ required: false, message: '请选择用户', trigger: 'blur' }]"
        >
          <el-input
            :disabled="true"
            v-model="item.userName"
            clearable
            :ref="`input${index}`"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="10">
        <el-form-item
          label="企业微信ID"
          :prop="'items.' + index + '.entWxUserId'"
          :rules="[
            { required: true, message: '请输入企业微信ID', trigger: 'blur' },
          ]"
        >
          <el-input
            :disabled="submitting"
            v-model.trim="item.entWxUserId"
            clearable
            :ref="`input${index}`"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item label="操作" prop="userCountLimit"
          ><el-button @click="onDeleteRow(index)" :disabled="submitting"
            >删除</el-button
          ></el-form-item
        >
      </el-col>
    </el-row>
    <el-form-item style="text-align: right; margin-top: 30px">
      <el-button @click="onCancel" :disabled="submitting">关 闭</el-button>
      <!-- <el-button @click="addRow" :disabled="submitting">新增一行</el-button> -->
      <el-button
        type="primary"
        :loading="submitting"
        @click="submitForm('form')"
        >保存</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { createUniqueString } from "@/utils/common";
import { PutEntWxUserUpdate } from "../api";
import { ShowApiError } from "@/request/error";
export default {
  props: {
    userData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      submitting: false,
      focusIndex: 0,
      form: {
        items: [
          {
            userId: null,
            entWxUserId: "",
            key: this.createUniqueString(),
          },
        ],
      },
    };
  },
  watch: {
    userData: {
      handler() {
        if (this.userData.length > 0) {
          this.form.items = [];
          this.userData.forEach((user) => {
            if (user.canBindEntWx) {
              this.form.items.push({
                userName: user.name,
                userId: user.id,
                entWxUserId: user.entWxUserId,
                key: this.createUniqueString(),
              });
            }
          });
          console.log('表单数据', this.form.items)
        } else {
          this.form.items = [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    createUniqueString,
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let datas = [];
          this.form.items.forEach((item) => {
            datas.push({
              userId: item.userId,
              entWxUserId: item.entWxUserId,
            });
          });
          this.submitting = true;
          PutEntWxUserUpdate(datas)
            .then(() => {
              this.submitting = false;
              this.initForm();
              this.$message.success("修改成功");
              this.$emit("success");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError(err, "批量修改异常");
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    initForm() {
      this.form = {
        items: [
          {
            userId: null,
            entWxUserId: "",
            key: this.createUniqueString(),
          },
        ],
      };
    },
    addRow(inputObj = "") {
      this.form.items.push({
        userId: null,
        entWxUserId: "",
        key: this.createUniqueString(),
      });
      this.$nextTick(() => {
        if (this.$refs[inputObj]) {
          this.$refs[inputObj][0].focus();
        }
      });
    },
    onDeleteRow(index) {
      if (this.form.items.length > 1) {
        this.form.items.splice(index, 1);
      }
    },
    onCancel() {
      this.initForm();
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.form-tips {
  font-size: 13px;
  text-align: left;
  padding: 10px;
}
</style>