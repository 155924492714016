<template>
  <section class="filter-wrap base-shadow">
    <el-form ref="form" :model="tableFilters" label-width="auto">
      <el-row :gutter="20">
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="名称">
            <el-input
              v-model="tableFilters.name"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="用户登录名">
            <el-input
              v-model="tableFilters.username"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="电话号码">
            <el-input
              v-model="tableFilters.phoneNo"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <template v-if="showMoreFilter">
           <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="激活状态">
              <BasicSelect
                v-model="tableFilters.activated"
                :allOptions="activatedOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="部门">
              <BasicSelect
                v-model="tableFilters.departmentId"
                :allOptions="departmentOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="角色">
              <BasicSelect
                v-model="tableFilters.roleIds"
                keyName="name"
                :allOptions="rolesOptions"
              />
            </el-form-item>
          </el-col>

          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="创建日期">
              <el-date-picker
                v-model="tableFilters.createdAt"
                type="daterange"
                style="width: 100%"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="最后修改日期">
              <el-date-picker
                v-model="tableFilters.lastModifiedAt"
                type="daterange"
                style="width: 100%"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="创建人">
              <BasicSelect
                v-model="tableFilters.createdById"
                :allOptions="userOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="最后修改用户">
              <BasicSelect
                v-model="tableFilters.lastModifiedById"
                :allOptions="userOptions"
              />
            </el-form-item>
          </el-col>
        </template>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onResetFilter">重置</el-button>
            <el-button type="text" @click="showMoreFilter = !showMoreFilter">
              更多
              <i
                :class="
                  showMoreFilter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
              ></i>
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </section>
</template>

<script>
import formSelection from "../mixins/formSelection";
import BasicSelect from "@/components/BasicSelect";
import { onClearFilter } from "@/utils/common";
export default {
  components: {
    BasicSelect,
  },
  mixins: [formSelection],
  data() {
    return {
      showMoreFilter: false,
      activatedOptions:[
        {id: true,name:"已激活"},
        {id: false,name:"未激活"},
      ],
      tableFilters: {
        createdAt: [],
        lastModifiedAt: [],
        createdById: null,
        lastModifiedById: null,
        departmentId: null,
        activated: null,
        roleIds: [],
        name: "",
        phoneNo: "",
        username: "",
      },
      propObj:{}
    };
  },
  created() {
    this.initFormOptions();
  },
  methods: {
    onSearch() {
      let filterParams = {};
      for (const key in this.tableFilters) {
        if (this.propObj[key]) {
          filterParams[this.propObj[key]] = this.tableFilters[key];
        } else {
          filterParams[key] = this.tableFilters[key];
        }
      }
      this.$emit("change", filterParams);
      this.$emit("confirm", filterParams);
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.$emit("change", this.tableFilters);
      this.$emit("clear", this.tableFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
  box-sizing: border-box;
  margin: 15px;
  margin-bottom: 0;
  padding: 22px;
  padding-bottom: 0;
  background-color: #fff;
}
</style>