<template>
  <el-form
    :model="form"
    ref="userForm"
    label-width="130px"
    class="company-ruleForm"
    status-icon
    v-loading="loading"
  >
    <el-row :gutter="10">
      <el-col :span="12">
        <el-form-item label="是否激活：" prop="activated">
          <el-tag type="success" v-if="form.activated">已激活</el-tag>
          <el-tag type="danger" v-if="!form.activated">未激活</el-tag>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="是否禁用：" prop="activated">
          <el-tag type="success" v-if="!form.disabled">已启用</el-tag>
          <el-tag type="danger" v-if="form.disabled">已禁用</el-tag>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="用户名：" prop="name">
          <span>{{ form.name }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="登录账户名：" prop="username">
          <span>{{ form.username }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="企业微信ID：" prop="entWxUserId">
          <span>{{ form.entWxUserId }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="电话号码：" prop="phoneNo">
          <span>{{ form.phoneNo }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="绑定微信小程序：" prop="wxMiniAppBound">
          <el-tag type="success" v-if="form.wxMiniAppBound">已绑定</el-tag>
          <el-tag type="danger" v-if="!form.wxMiniAppBound">未绑定</el-tag>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="所属公司：" prop="companyName">
          <span>{{ form.companyName }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="所属部门：" prop="departmentName">
          <span>{{ form.departmentName }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="创建时间：" prop="createdAt">
          <span>{{ form.createdAt | date_time }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="创建人：" prop="createdByName">
          <span>{{ form.createdByName }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="用户角色：" prop="roles">
          <el-tag
            class="tag"
            v-for="item in form.roles"
            :key="item.id"
            type="info"
            style="margin-right: 10px; margin-bottom: 10px"
          >
            <i class="el-icon-paperclip"></i>
            {{ item.name }}
          </el-tag>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { GetUserById } from "../api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
export default {
  props: {
    id: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      form: {},
    };
  },
  watch: {
    id: {
      handler() {
        if (!isEmprty(this.id)) {
          this.getFormData(this.id);
        } else {
          this.form = {};
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    isEmprty,
    getFormData(id = "") {
      this.loading = true;
      GetUserById(id)
        .then((res) => {
          this.form = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取详情", err);
        });
    },
  },
};
</script>

<style>
</style>